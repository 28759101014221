import { PermsTable } from "../interfaces/Permissions";
// permet de choisir quelles tables vont apparaître dans les permissions
// la key de l'objet se base sur le from de l'OptionReducer
export const configPerms = {
    Calendar: [PermsTable.Add, PermsTable.Update, PermsTable.Delete, PermsTable.AllowShareSynchro, PermsTable.AllowEditMeetingScheduler],
    SharedCalendar: [PermsTable.Add, PermsTable.Update, PermsTable.Delete, PermsTable.AllowShareSynchro, PermsTable.AllowEditMeetingScheduler],
    Booking: [PermsTable.Add, PermsTable.Update, PermsTable.Delete],
    Parking: [PermsTable.Add, PermsTable.Update, PermsTable.Delete],
    Contact: [PermsTable.Add, PermsTable.Update, PermsTable.Delete],
    Keepass: [PermsTable.Add, PermsTable.Update, PermsTable.Delete],
};
