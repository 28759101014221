import React from "react";
import { Button, Flex, Input, LocationIcon, MenuButton, MoreIcon, SearchIcon } from "@fluentui/react-northstar";
import { useNavbar } from "./Navbar.logic";
import { memo } from "react";
import SideViewPlaces from "../../components/SideView/SideViewPlaces/SideViewPlaces";
import { ISideView } from "../../types/ISideView/ISideView";
import SideViewTrips from "../../components/SideView/SideViewTrips/SideViewTrips";
import { IConnectedApp } from "../../types/IConnectedApp/IConnectedApp";
import CalendarNav from "../../components/CalendarNav/CalendarNav";
import { faRoute } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InformationMenu } from "front";

const Navbar = memo(() => {
  const logic = useNavbar();

  return (
    <>
      <Flex
        gap="gap.small"
        styles={{
          padding: "1rem",
          overflowX: logic.isOnMobile ? "scroll" : "initial",
          position: logic.isOnMobile ? "initial" : "relative",
          zIndex: 10000,
          justifyContent: logic.connectedApp ? "space-between" : "end",
        }}
      >
        {logic.connectedApp === IConnectedApp.CONTACTS_PRO && (
          <Input icon={<SearchIcon />} placeholder={logic.t("Search")} iconPosition="start" fluid inverted {...logic.register("search")} style={{ minWidth: logic.isOnMobile ? "200px" : "initial" }} />
        )}
        {logic.connectedApp === IConnectedApp.CALENDAR_PRO && <CalendarNav />}
        <Flex gap="gap.small">
          <Button data-testid="nav-places-btn" primary icon={<LocationIcon />} content={logic.t("titleMenuPlaces")} onClick={logic.handlePlaces} style={{ minWidth: "120px" }} />
          <Button data-testid="nav-trips-btn" primary icon={<FontAwesomeIcon icon={faRoute} />} content={logic.t("titleMenuTrips")} onClick={logic.handleTrips} style={{ minWidth: "150px" }} />

          <MenuButton trigger={<Button icon={<MoreIcon outline />} iconOnly />} menu={logic.menus.moreMenu} on="click" />
          <InformationMenu />
        </Flex>
      </Flex>
      <SideViewPlaces isOpen={logic.sideView === ISideView.PLACES} handleClose={logic.handleCloseSideView} />
      <SideViewTrips isOpen={logic.sideView === ISideView.TRIPS} handleClose={logic.handleCloseSideView} />
    </>
  );
});

export default Navbar;
